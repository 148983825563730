import { memo, useEffect, useMemo, useState } from "react";
import { Reorder, Close } from "@mui/icons-material";
import { Drawer } from "@mui/material";
import "./Header.css";
import Link from "next/link";
import useUser from "@/hooks/useUser";
import dynamic from "next/dynamic";

const CustomButton = dynamic(() => import("../CustomButton/CustomButton"));

const SelectCountry = dynamic(() => import("../SelectCountry/SelectCountry"), {
  ssr: false,
});

const Boutton = () => (
  <CustomButton label="Télécharge l'appli" className="button-download" />
);

const Header = () => {
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { isConected } = useUser();
  const [isClient, setIsClient] = useState(false); // New state to check if it's client-side

  useEffect(() => {
    // Update state after component mounts
    setIsClient(true);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };

  const dataLink = useMemo(() => {
    let links = [
      { nameitem: "Accueil", linkitem: "/" },
      { nameitem: "Business", linkitem: "/business" },
      { nameitem: "Blog", linkitem: "https://blog.lehub.online" },
      { nameitem: "À Propos", linkitem: "/a-propos" },
      { nameitem: "Contact", linkitem: "/contact" },
    ];
    if (isConected) {
      return [...links, { nameitem: "Profil", linkitem: "/profile" }];
    }
    return [...links, { nameitem: "Connexion", linkitem: "/connexion" }];
  }, [isConected]);

  if (!isClient) return null;
  return (
    <header className={`appHeader ${isClient && scrolled ? "scrolled" : ""}`}>
      <nav className="navbar">
        <Link href="/">
          <div className="logo-container" />
        </Link>
        <div className="navbar-left">
          <div className="navbar-listContainer">
            <ul className="navbar-list">
              <li style={{ marginRight: "30px" }}>
                <SelectCountry />
              </li>
              <li className="navbar-item">
                <Link href="/">
                  <span style={{ color: "white" }}>Accueil</span>
                </Link>
              </li>
              <li className="navbar-item">
                <Link href="/business">
                  <span style={{ color: "white" }}>Business</span>
                </Link>
              </li>
              <li className="navbar-item">
                <Link href="https://blog.lehub.online" target="_blank">
                  <span style={{ color: "white" }}>Blog</span>
                </Link>
              </li>
              <li className="navbar-item">
                <Link href="/a-propos">
                  <span style={{ color: "white" }}>À propos</span>
                </Link>
              </li>
              <li className="navbar-item">
                <Link href="/contact">
                  <span style={{ color: "white" }}>Contact</span>
                </Link>
              </li>
              {isClient && // Render after client-side mount
                (isConected ? (
                  <li className="navbar-item">
                    <Link href="/profile">
                      <span style={{ color: "white" }}>Profil</span>
                    </Link>
                  </li>
                ) : (
                  <li className="navbar-item">
                    <Link href="/connexion">
                      <span style={{ color: "white" }}>Connexion</span>
                    </Link>
                  </li>
                ))}
              <li>
                <Link href={"/telechargement"}>
                  <Boutton />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="navbar-sidebar">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Link href={"/telechargement"} onClick={() => setOpen(false)}>
              <Boutton />
            </Link>
          </div>
          <div style={{ cursor: "pointer" }} onClick={() => toggleDrawer()}>
            {open ? (
              <Close style={{ color: "white" }} />
            ) : (
              <Reorder style={{ color: "white" }} />
            )}
          </div>
          <Drawer open={open} onClose={() => toggleDrawer()} anchor="right">
            <div className="navbar-sidebar-drawer">
              <div>
                <SelectCountry cbSelect={() => setOpen(false)} />
              </div>
              {dataLink.map((item, index) => {
                return (
                  <div className="sidebar-drawer-item" key={item.nameitem}>
                    <Link
                      target={
                        item.nameitem.toLowerCase().includes("blog")
                          ? "_blank"
                          : "_self"
                      }
                      onClick={() => setOpen(false)}
                      href={item.linkitem}
                    >
                      <span style={{ color: "white" }}>{item.nameitem}</span>
                    </Link>
                  </div>
                );
              })}
            </div>
          </Drawer>
        </div>
      </nav>
    </header>
  );
};

export default memo(Header);
